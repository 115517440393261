import dynamic from "next/dynamic"

export const ICONS = {
    ArrowRight: dynamic(() => import("@/assets/icons/ArrowRight.svg")),
    ArrowLeft: dynamic(() => import("@/assets/icons/ArrowLeft.svg")),
    Plus: dynamic(() => import("@/assets/icons/Plus.svg")),
    Timer: dynamic(() => import("@/assets/icons/Timer.svg")),
    Close: dynamic(() => import("@/assets/icons/Close.svg")),
    Check: dynamic(() => import("@/assets/icons/Check.svg")),
    CheckCircle: dynamic(() => import("@/assets/icons/CheckCircle.svg")),
    ThumbUp: dynamic(() => import("@/assets/icons/ThumbUp.svg")),
    QuestionCircle: dynamic(() => import("@/assets/icons/QuestionCircle.svg")),
    Comment: dynamic(() => import("@/assets/icons/Comment.svg")),
    InfoCircle: dynamic(() => import("@/assets/icons/InfoCircle.svg")),
    ExclamationCircle: dynamic(() => import("@/assets/icons/ExclamationCircle.svg")),
    CaretUp: dynamic(() => import("@/assets/icons/CaretUp.svg")),
    CaretDown: dynamic(() => import("@/assets/icons/CaretDown.svg")),
    CaretRight: dynamic(() => import("@/assets/icons/CaretRight.svg")),
    Account: dynamic(() => import("@/assets/icons/Account.svg")),
    Indicator: dynamic(() => import("@/assets/icons/Indicator.svg")),
    Email: dynamic(() => import("@/assets/icons/Email.svg")),
    Menu: dynamic(() => import("@/assets/icons/Menu.svg")),
    Message: dynamic(() => import("@/assets/icons/Message.svg")),
    CSV: dynamic(() => import("@/assets/icons/CSV.svg")),
    Copy: dynamic(() => import("@/assets/icons/Copy.svg")),
    StarOff: dynamic(() => import("@/assets/icons/StarOff.svg")),
    Send: dynamic(() => import("@/assets/icons/Send.svg")),
    ClosePage: dynamic(() => import("@/assets/icons/ClosePage.svg")),

    /* Multicolor */
    MulticolorSuccess: dynamic(() => import("@/assets/icons/MulticolorSucces.svg")),
    MulticolorError: dynamic(() => import("@/assets/icons/MulticolorError.svg")),

    /* Skill Icons */
    AttentionToDetails: dynamic(() => import("@/assets/icons/AttentionToDetails.svg")),
    CognitiveSkills: dynamic(() => import("@/assets/icons/CognitiveSkills.svg")),
    ContentMarketing: dynamic(() => import("@/assets/icons/ContentMarketing.svg")),
    Ecommerce: dynamic(() => import("@/assets/icons/Ecommerce.svg")),
    EmailMarketing: dynamic(() => import("@/assets/icons/EmailMarketing.svg")),
    FacebookAds: dynamic(() => import("@/assets/icons/FacebookAds.svg")),
    GoogleAds: dynamic(() => import("@/assets/icons/GoogleAds.svg")),
    GoogleAnalytics: dynamic(() => import("@/assets/icons/GoogleAnalytics.svg")),
    LogicalThinking: dynamic(() => import("@/assets/icons/LogicalThinking.svg")),
    MarketingAutomation: dynamic(() => import("@/assets/icons/MarketingAutomation.svg")),
    MarketingB2B: dynamic(() => import("@/assets/icons/MarketingB2B.svg")),
    OnlinePresence: dynamic(() => import("@/assets/icons/OnlinePresence.svg")),
    Seo: dynamic(() => import("@/assets/icons/Seo.svg")),
    SocialCampaing: dynamic(() => import("@/assets/icons/SocialCampaing.svg")),
    TechnicalSeo: dynamic(() => import("@/assets/icons/TechnicalSeo.svg")),

    /* Sales and Business */
    AccountExecutive: dynamic(() => import("@/assets/icons/AccountExecutive.svg")),
    Prospecting: dynamic(() => import("@/assets/icons/Prospecting.svg")),
    TalentAcquisition: dynamic(() => import("@/assets/icons/TalentAcquisition.svg")),

    /* Work Mindset */
    ApproachToChallenges: dynamic(() => import("@/assets/icons/ApproachToChallenges.svg")),
    ApproachToUncertainty: dynamic(() => import("@/assets/icons/ApproachToUncertainty.svg")),
    ApproachToMakingDecisions: dynamic(() => import("@/assets/icons/ApproachToMakingDecisions.svg")),
    ApproachToWork: dynamic(() => import("@/assets/icons/ApproachToWork.svg")),

    /* Logo */
    Logo: dynamic(() => import("@/assets/icons/Logo.svg")),

    /* Loader */
    LoadingSpinnerOnLightBackground: dynamic(() => import("@/assets/icons/LoadingSpinnerOnLightBackground.svg")),
    LoadingSpinnerOnDarkBackground: dynamic(() => import("@/assets/icons/LoadingSpinnerOnDarkBackground.svg")),

    /* Flags */
    Poland: dynamic(() => import("@/assets/icons/Poland.svg")),
    GreatBritain: dynamic(() => import("@/assets/icons/GreatBritain.svg")),

    /* Illustrations */
    Login: dynamic(() => import("@/assets/ilustrations/Login.svg")),
    Steps: dynamic(() => import("@/assets/ilustrations/Steps.svg")),
    Onboarding: dynamic(() => import("@/assets/ilustrations/Onboarding.svg")),
    Research: dynamic(() => import("@/assets/ilustrations/Research.svg")),
    Target: dynamic(() => import("@/assets/ilustrations/Target.svg")),
}
